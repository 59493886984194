import React from "react";
import "./Music-content.css";
import {
  WindBandCompositions,
  BrassBandCompositions,
  ChamberCompositions,
} from "./compositions";
import CompositionBlurb from "./composition-blurb/Composition-blurb";
import MusicQuickNavBar from "../music-quick-nav";

function MusicContent({ classNameInfo }: { classNameInfo?: string }) {
  return (
    <div className={`${classNameInfo || ""}`}>
      <h2>Music</h2>
      <MusicQuickNavBar />
      <section id="Band">
        <h2>Wind band</h2>
        <p>
          Please check out my publishers' websites for PDF score samples, audio
          recordings and ordering info. Audio recordings of my self-published
          works may be found on YouTube. For self-published works, please reach
          out to be if you are interested in purchasing scores and parts.
        </p>
        <p>
          <a target="_blank" href="https://barnhouse.com">
            CL Barnhouse Company
          </a>
        </p>
        <p>
          <a target="_blank" href="https://enpmusic.com">
            Eighth Note Publications{" "}
          </a>
        </p>

        {WindBandCompositions.map((bandComposition, index) => (
          <CompositionBlurb
            compositionItem={bandComposition}
            showInstrumentation={false}
            key={index}
          />
        ))}
      </section>
      <section id="Chamber">
        <h2>Chamber Music / Solo </h2>
        {ChamberCompositions.map((chamberComposition, index) => (
          <CompositionBlurb
            compositionItem={chamberComposition}
            key={index}
            showInstrumentation={true}
          />
        ))}
      </section>
      <section id="Brass">
        <h2>Brass band / Brass choir</h2>
        {BrassBandCompositions.map((brassComposition, index) => (
          <CompositionBlurb
            compositionItem={brassComposition}
            showInstrumentation={false}
            key={index}
          />
        ))}
      </section>
      <section id="Other">
        <h2>Chip tunes / Video Game Music</h2>
      </section>
      <section>
        <h2>Podcasts / New Media</h2>
      </section>
      <section>
        <h2>Notable works</h2>
      </section>
    </div>
  );
}

export default MusicContent;
