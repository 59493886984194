import React from "react";
import { Link } from "react-router-dom";
import "./Blurb.css";
function HomeContentBlurb({ classNameInfo }: { classNameInfo?: string }) {
  return (
    <div className={`${classNameInfo || ""}`}>
      <h2>Welcome</h2>
      <p>I'm a composer and software developer.</p>
      <p>This website features my music and dev related projects.</p>
      <h2>What's new</h2>
      <p>
        Check out the <Link to={"/news"}> </Link> section to read about new
        music and projects I am working on.
      </p>
    </div>
  );
}

export default HomeContentBlurb;
