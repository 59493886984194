import TopNav from "../../components/top-nav";
import "./Home-page-body.css";
import "./Home.css";

import MainProfilePhoto from "../../assets/images/main-profile-image.jpg";
import HomeContentBlurb from "./content";
function Home() {
  return (
    <div className="Home-page__main">
      <TopNav activeLink="home" />
      <div className="Home-page__body">
        <div className="Home-page__body__content">
          <img
            src={MainProfilePhoto}
            className="Home-page__body__main-profile-photo"
          />
          <HomeContentBlurb />
        </div>
      </div>
      <div>
        <footer>
          <p className="updated_footer">Updated December 1, 2023</p>
        </footer>
      </div>
    </div>
  );
}

export default Home;
