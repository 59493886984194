import emailjs from "emailjs-com";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TopNav from "../../components/top-nav";
import "./Contact.css";

function Contact() {
  const [userMessageSent, setUserMessageSent] = useState<boolean>(false);
  const [errorReceived, setErrorReceived] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [continueButtonClicked, setContinueButtonClicked] = useState(false);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);
  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
    setSubmitButtonEnabled(false);
    setErrorMessage("");
    setErrorReceived(false);
    try {
      await emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string,
        e.target,
        process.env.REACT_APP_EMAILJS_USER_ID as string
      );
      window.sessionStorage.setItem("reloaded", "false");
      setUserMessageSent(true);
    } catch (error) {
      console.log("send e-mail error", error);
      setErrorReceived(true);
      setErrorMessage(`We were unable to send the message. ${error}`);
    }
  };

  const showForm = (e: any) => {
    window.sessionStorage.setItem("reloaded", "true");
    window.location.reload();
    setContinueButtonClicked(true);
  };

  useEffect(() => {
    const reloaded = window.sessionStorage.getItem("reloaded");
    if (reloaded === "true") {
      setContinueButtonClicked(true);
      window.sessionStorage.setItem("reloaded", "false");
    }
  }, []);
  return (
    <div className="Contact-page__main">
      <TopNav activeLink="contact" />
      <div className="Contact-page__body">
        {!continueButtonClicked && (
          <div>
            <p
              id="continue-button"
              className="Contact-page__form__send-message-link"
              onClick={showForm}
            >
              Send me a message
            </p>
          </div>
        )}
        {!userMessageSent && continueButtonClicked && (
          <form className="Contact-page__form__main" onSubmit={handleOnSubmit}>
            <div>
              <h2> Contact Me</h2>
            </div>
            <div>
              <label>Your Name</label>
            </div>
            <div>
              <input
                id="from-name"
                placeholder="name"
                name="from_name"
                required
              />
            </div>
            <div>
              <label>Your Email</label>
            </div>
            <div>
              <input
                placeholder="email"
                type="email"
                name="user_email"
                id="from-email"
                required
              />
            </div>
            <div>
              <label>Message</label>
            </div>
            <div>
              <textarea
                placeholder="message"
                name="message"
                id="from-message"
                required
              />
            </div>
            <br />
            <br />

            {submitButtonEnabled ? (
              <div>
                <button type="submit" value="Submit" id="submit_button">
                  Submit
                </button>
              </div>
            ) : (
              <>
                <button
                  type="submit"
                  value="Submit"
                  id="submit_button"
                  disabled
                >
                  Submit
                </button>
              </>
            )}
          </form>
        )}
        {userMessageSent && (
          <div className="Contact-page__message-received">
            <h4 className="Contact-page__message-received__success-message">
              Thank you for your message.
            </h4>
            <Link to="/">Home</Link>
          </div>
        )}
        {errorReceived && (
          <div className="Contact-page__error">
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Contact;
