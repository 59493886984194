import React from "react";
import "./Music-quick-nav-bar.css";
function MusicQuickNavBar() {
  return (
    <div className="Music-quick-nav__main">
      <nav className="Music-quick-nav__main__content">
        <ul>
          <li>
            <a href="#Band">Band</a>
          </li>
          <li>
            <a href="#Brass">Brass</a>
          </li>
          <li>
            <a href="#Chamber">Chambre</a>
          </li>
          <li>
            <a href="#Other">Other</a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default MusicQuickNavBar;
