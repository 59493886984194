import React from "react";

import "./Bio-content.css";
function BioContent({ classNameInfo }: { classNameInfo?: string }) {
  return (
    <div className={`${classNameInfo ?? ""}bio-content`}>
      <h2>Bio</h2>
      <p>
        David Eastmond (b. 1981) holds a Bachelor of Music degree from the
        University of Toronto, where he studied clarinet, music education,
        composition and French language studies. He studied clarinet with Peter
        Stoll and composition with Chan Ka Nin, John Hawkins, and Christos
        Hatzis.
      </p>
      <p>
        David obtained a diploma in full-stack web development where he learned
        to utilize technologies like HTML, CSS, JavaScript, jQuery, Node.js,
        React.js, Ruby, Python, .Net Framework, (Objective-C/Swift), MongoDB,
        PostgreSQL, and ActiveRecord.
      </p>
      <p>
        Growing up and residing in Brampton, Ontario, David’s passion for wind
        band music and educational music in general started in secondary school,
        and was further honed as a clarinettist playing in various local
        community ensembles.
      </p>
      <p>
        David’s first wind band composition “A Sailor’s Tale” was written in his
        3rd year of secondary school and enjoyed performances by ensembles such
        as the Longwood University Concert Band, Dr. Gordon Ring conductor.
      </p>
      <p>
        A freelance composer, David’s music is performed by various ensembles
        around the world. His wind band composition “Saugeen Shores Overture”
        was commissioned by Southampton Summer Music and premiered by the
        Southampton Festival Winds, David Leach, Conductor in 2017.
      </p>
      <p>
        David's passion for education lead him to complete a certification in
        Teaching English as a Second Language with International House Schools
        (Toronto).
      </p>
      <p>David is currently a software developer and a freelance composer.</p>
    </div>
  );
}

export default BioContent;
