import React from "react";
import { ICompositionCatalogItem } from "../compositions/types";
import "./Composition-blurb.css";
function CompositionBlurb({
  compositionItem,
  showInstrumentation,
}: {
  compositionItem: ICompositionCatalogItem;
  showInstrumentation: boolean;
}) {
  return (
    <div className="Composition-item">
      <h4 className="composition-title">{compositionItem.title}</h4>
      <a target="_blank" href={compositionItem.publisherLink}>
        <img src={compositionItem.imageUrl} className="score-image" />
      </a>
      <br />
      <a>Gr. {compositionItem.gradeLevel}</a>
      {compositionItem.audioRecordingLink && (
        <a
          className="Composition-item__audio-link"
          href={compositionItem.audioRecordingLink}
        >
          {" "}
          Audio
        </a>
      )}
      <br />
      <br />
      <a>{compositionItem.description}</a>
      <br />
      {showInstrumentation && compositionItem.instrumentation && (
        <p>Instrumentation: {compositionItem.instrumentation}</p>
      )}
    </div>
  );
}

export default CompositionBlurb;
