import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Bio from "./pages/bio";
import Contact from "./pages/contact";
import Dev from "./pages/dev";
import Home from "./pages/home";
import Media from "./pages/media";
import Music from "./pages/music";
import News from "./pages/news";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/news" component={News} />
        <Route exact path="/music" component={Music} />
        <Route exact path="/dev" component={Dev} />
        <Route exact path="/bio" component={Bio} />
        <Route exact path="/media" component={Media} />
        <Route exact path="/contact" component={Contact} />
      </Switch>
    </Router>
  );
}

export default App;
