import React from "react";
import TopNav from "../../components/top-nav";
import { projects } from "./content/project";
import ProjectBlurb from "./content/project-burb";
import "./Dev.css";
function Dev() {
  return (
    <div className="Dev-page__main">
      <TopNav activeLink="dev" />
      <div className="Dev-page__body">
        <div className="Dev-page__body__content">
          <section>
            <h2>Languages, stacks and frameworks</h2>
            <ul>
              <li>JavaScript/HTML/CSS</li>
              <li>React</li>
              <li>Typescript</li>
              <li>Nodejs</li>
              <li>MongoDB</li>
              <li>SQL</li>
              <li>C#</li>
            </ul>
          </section>
          <br />
          <br />
          <section>
            <h2>
              <a href="https://github.com/davideastmond" target="_blank">
                Github
              </a>
            </h2>
          </section>
          <br />
          <section className="section-projects-main">
            <h2>Portfolio</h2>
            {projects.map((project, index) => (
              <ProjectBlurb {...{ project }} key={index} />
            ))}
            <br />
          </section>
        </div>
      </div>
    </div>
  );
}

export default Dev;
