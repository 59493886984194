import React from "react";
import { IProject } from "../project/types";
import "./Project-blurb.css";
function ProjectBlurb({ project }: { project: IProject }) {
  return (
    <div className="Project-blurb__main">
      <div className="Project-blurb__main__body">
        <div className="Project-blurb__main__body__project-title">
          <h3 className="Project-blurb__main__body__app-title">
            <a target="_blank" href={project.githubLink}>
              {project.name}{" "}
            </a>
          </h3>
        </div>
        {project.alternateGithubLink && (
          <a
            className="Project-blurb__main__body__alt-link"
            href={project.alternateGithubLink.url}
          >
            {project.alternateGithubLink.label}
          </a>
        )}
        <div className="Project-blurb__main__body__project-description">
          <p className="Project-blurb__main__body__description-text">
            {project.description}
          </p>
        </div>
        <div className="Project-blurb__main__body__project-stack">
          <p className="Project-blurb__main__body__project-stack__stack-tag">
            stack: {project.stackTags.join(", ")}
          </p>
        </div>
        <div className="Project-blurb__main__body__project-screen-caps">
          {project.screenCapUrls &&
            project.screenCapUrls.length > 0 &&
            project.screenCapUrls.map((url, index) => (
              <img
                className="project-screen-caps-image"
                src={url}
                key={index}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default ProjectBlurb;
