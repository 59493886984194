import React from "react";
import TopNav from "../../components/top-nav";
import MusicContent from "./content";
import "./Music.css";
function Music() {
  return (
    <div className="Music-page__main">
      <TopNav activeLink="music" />
      <div className="Music-page__body">
        <div className="Music-page__body__content">
          <MusicContent />
        </div>
      </div>
    </div>
  );
}

export default Music;
