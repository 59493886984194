import React from "react";
import TopNav from "../../components/top-nav";
import "./Bio.css";
import BioContent from "./content";
import BioProfilePhoto from "../../assets/images/bio-profile-image.jpg";
function Bio() {
  return (
    <div className="Bio-page__main">
      <TopNav activeLink="bio" />
      <div className="Bio-page__body">
        <div className="Bio-page__body__content">
          <img
            src={BioProfilePhoto}
            className="Bio-page__body__content__bio-photo"
          />
          <BioContent />
        </div>
      </div>
    </div>
  );
}
export default Bio;
