import React, { useState } from "react";
import MenuItems from "../menu-items";
import "./Top-nav.css";
function TopNav({ activeLink }: { activeLink?: string }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div className="Top-bar">
      <MenuItems classNameInfo="Top-bar__nav" activeLink={activeLink} />
      <i
        className="bi bi-list Top-bar__burger"
        onClick={() => setMenuOpen(!menuOpen)}
      ></i>

      {menuOpen && (
        <div className="Nav__menu-overlay" onClick={closeMenu}>
          <div className="Nav__slide-menu" onClick={closeMenu}>
            <MenuItems />
          </div>
        </div>
      )}
    </div>
  );
}

export default TopNav;
