import React from "react";
import TopNav from "../../components/top-nav";
import "./News.css";
function News() {
  return (
    <div className="News-page__main">
      <TopNav activeLink="news" />
      <div className="News-page__body">
        <div className="News-page__body__content">
          <p>News section</p>
        </div>
      </div>
    </div>
  );
}

export default News;
