import React from "react";
import TopNav from "../../components/top-nav";
import "./Media.css";

function Media() {
  return (
    <div className="Media-page__main">
      <TopNav activeLink="media" />
      <div className="Media-page__body">
        <p> Media page</p>
      </div>
    </div>
  );
}

export default Media;
