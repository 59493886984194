import { ICompositionCatalogItem } from "./types";

import BrassBandTheBuilderImage from "../../../../assets/images/composition-cover-images/BC19117-brass-builder.jpg";
import WeRiseWithTheMountainsImage from "../../../../assets/images/composition-cover-images/CB222439-rise-mountains.jpg";
import BeyondTheBluePlanetImage from "../../../../assets/images/composition-cover-images/beyond-the-blue-planet-enp-2020.jpg";
import GenericGrade2Band from "../../../../assets/images/composition-cover-images/cb-2-generic-score-cover.png";
import ChamberGenericGrade35 from "../../../../assets/images/composition-cover-images/chamber-3-5-generic-score-cover.png";
import ExultiaImage from "../../../../assets/images/composition-cover-images/ex012-3513-00.jpg";
import GenericGrade35Band from "../../../../assets/images/composition-cover-images/generic-cb-35.png";
import GenericGrade4Band from "../../../../assets/images/composition-cover-images/generic-cb-score-gr-4-template.png";
import KanataSpringImage from "../../../../assets/images/composition-cover-images/ks012-3599-00.jpg";
import OneCourageousVoiceImage from "../../../../assets/images/composition-cover-images/one-courageous-voice-011-4738-00.jpg";
import SailorsTaleImage from "../../../../assets/images/composition-cover-images/sailors-tale-012-3411-00.jpg";
import SLChroniclesImage from "../../../../assets/images/composition-cover-images/slc-012-3699-00.jpg";
import SoloChamberClarinetGenericScoreCover from "../../../../assets/images/composition-cover-images/solo-chamber-clarinet-generic.png";
import SaugeenShoresOvertureImage from "../../../../assets/images/composition-cover-images/sso_cover.jpg";
import TheBuilderImage from "../../../../assets/images/composition-cover-images/the-builder-CB19378.jpg";

export const WindBandCompositions: ICompositionCatalogItem[] = [
  {
    title: "We Rise with the Mountains",
    description: `This programmatic work depicts the rugged beauty of the outdoors while acknowledging the danger it can pose. 
      This piece musically describes a cliff-side rescue by emergency responders after a freak accident on a hiking trip.`,
    gradeLevel: "2",
    publisherLink: "https://enpmusic.com/details.php?CatalogueNo=CB222439",
    imageUrl: WeRiseWithTheMountainsImage,
    year: "2022",
  },
  {
    title: "Flower City Festival",
    description: `An overture for wind band at the Grade 2.5 level.
      Brampton, ON, Canada was given the nickname Flower Town. This composition is dedicated to this now bustling, diverse Flower City.
      
      I open the composition with a fanfare, followed by expositional thematic material.
      The slower inner section maximizes opportunities for expressive melodic playing.
      After a smooth transition from a lyrical mood back to the familiar fanfare we heard at the beginning, I recapitulate the main themes and conclude the piece with a festive coda.`,
    gradeLevel: "2.5",
    audioRecordingLink: "https://youtu.be/iKh1y8B6omg",
    year: "2021",
    imageUrl: GenericGrade2Band,
  },
  {
    title: "Humberview Escapade",
    description:
      "Rhythms and melodic themes interplay, resulting in an energizing, modern work for more technically mature bands. Great for festival and contest performances.",
    gradeLevel: "4.5",
    audioRecordingLink: "https://youtu.be/b7SM3x4NV3o",
    year: "2021",
    imageUrl: GenericGrade4Band,
  },
  {
    title: "Persevere",
    description:
      "A COVID-19 era young band composition. Optimistic in tone, the themes here are the source of the material featured in my Divertimento for Clarinet Ensemble. Self-published. Please contact me regarding sales of scores and parts.",
    audioRecordingLink: "https://www.youtube.com/watch?v=-zrkv3ZYehM",
    imageUrl: GenericGrade2Band,
    gradeLevel: "2.5",
    year: "2020",
  },
  {
    title: "Tributes IV",
    description:
      "Written in a tonal romantic/heroic/cinematic style. I wrote this as a tribute to teachers, educators, and their efforts throughout this experience (COVID-19 pandemic) we are undergoing.",
    audioRecordingLink: "https://www.youtube.com/watch?v=ODFppjva7c0",
    gradeLevel: "4",
    imageUrl: GenericGrade4Band,
    year: "2020",
  },
  {
    title: "Beyond the Great Expanse",
    description:
      "Romatic/heroic/cinematic style piece for experienced bands, depicting the natural beauty of Canada's diverse, beautiful and rugged landscape.",
    audioRecordingLink: "https://www.youtube.com/watch?v=dQzjDxHrbiY",
    gradeLevel: "3.5",
    imageUrl: GenericGrade35Band,
    year: "2020",
  },
  {
    title: "Beyond the Blue Planet",
    description:
      "Beyond the Blue Planet is a big-sounding piece for young band that is inspired by humankind’s drive to increase its knowledge and explore the unknown. After an opening fanfare and exposition, the piece moves through many moods - from energetic and rousing, to reflective and ethereal.",
    imageUrl: BeyondTheBluePlanetImage,
    publisherLink: "https://enpmusic.com/details.php?CatalogueNo=CB220404",
    gradeLevel: "2",
    year: "2020",
  },
  {
    title: "We Rise With the Mountains",
    description:
      "This is a piece about the beauty of nature and a hike that went awry. After a brave rescue, we persevere.",
    audioRecordingLink: "https://www.youtube.com/watch?v=rZsAh3ty5Bc",
    imageUrl: GenericGrade2Band,
    gradeLevel: "2",
    year: "2019",
  },
  {
    title: "With one Courageous Voice",
    description:
      "With One Courageous Voice is an expressive and uplifting selection that honors the democratic process, and those who participate in it – both past and present – whether through voting, serving, or even drafting the founding documents which make our democracies what they are today. The music invokes ideas of compromise, self-reflection, history and duty. An introspective and uplifting work for the developing band!",
    imageUrl: OneCourageousVoiceImage,
    publisherLink: "https://www.barnhouse.com/product/011-4738-00/",
    gradeLevel: "2.5",
    year: "2019",
  },
  {
    title: "The Builder",
    description:
      "The Builder is a dedication to the creative process and to those who create - whatever that might be - from idea, to construction to the unveiling of the creation. Hammering, metallic sounds evoke working with something physical - perhaps some kind of flying machine. After many hours toiling, brainstorming and incubating in the workshop, the inventor finally lets their creation fly.",
    imageUrl: TheBuilderImage,
    publisherLink: "http://www.enpmusic.com/details.php?CatalogueNo=CB19378",
    gradeLevel: "2",
    year: "2019",
  },
  {
    title: "Saugeen Shores Overture",
    audioRecordingLink: "https://www.youtube.com/watch?v=mOKQ8NJygRs",
    description:
      "Saugeen Shores Overture was commissioned by Southampton Summer Music, a music education Summer camp in Bruce County, Ontario, in order to celebrate its 30th anniversary. It premiered in the summer of 2017, and was performed by the Southampton Summer Music Festival Winds, David Leach, conductor.",
    imageUrl: SaugeenShoresOvertureImage,
    gradeLevel: "4.5",
    year: "2017",
  },
  {
    title: "Exultia! An Overture for Band",
    description:
      "Exultia is a fresh and contemporary concert piece, celebratory in nature and perfect for concert or contest performance. Based on a single melodic theme that appears in a number of exciting variations and incarnations. Lots of driving rhythms and harmonic twists provide a composition that will hold your students and audiences’ attention.",
    gradeLevel: "3.5",
    imageUrl: ExultiaImage,
    year: "2006",
  },
  {
    title: "Kanata Spring",
    description:
      "An original hymn tune (loosely based on 'O Canada') forms the basis of bold fanfares, lush lyrical settings, dramatic melodies, imitative counterpoint and surprising harmonic twists. A variety of articulations and dynamic contrasts will show off your ensemble’s versatility.",
    year: "2007",
    gradeLevel: "3.5",
    imageUrl: KanataSpringImage,
    publisherLink: "https://www.barnhouse.com/product/012-3599-00/",
  },
  {
    title: "St. Lawrence Chronicles",
    description:
      "The immensity and beauty of this historic river is captured in bold brass fanfares. Haunting woodwind themes encapsulate the relentless exploration of the New World, and the lush, melodic slow section presents many opportunities for lyrical playing. Ideal for bands of moderate experience, this programmatic composition is perfect for contest, festival and concert performance.",
    year: "2008",
    gradeLevel: "2.5",
    imageUrl: SLChroniclesImage,
    publisherLink: "https://www.barnhouse.com/product/012-3599-00/",
  },
  {
    title: "A Sailor's Tale",
    description:
      "An impressive debut composition from a bright young Canadian composer. Bold and vigorous, this appealing work incorporates numerous meter changes and a wide range of dynamics. All sections of the band get into the action with interesting parts and there is even a short mixed meter section where the high woodwinds are accompanied by hand clapping from the rest of the band. A very interesting work that is a splendid choice for any concert or festival performance! Exceptional in every respect!",
    year: "2005",
    gradeLevel: "3.5",
    imageUrl: SailorsTaleImage,
    publisherLink: "https://www.barnhouse.com/product/012-3599-00/",
  },
].map((bandComposition) => ({
  ...bandComposition,
  category: "Wind Band",
}));

export const BrassBandCompositions: ICompositionCatalogItem[] = [
  {
    title: "The Builder (arr. for Brass Choir by D. Marlatt) ",
    description: "The Builder arranged for brass choir",
    imageUrl: BrassBandTheBuilderImage,
    gradeLevel: "3",
    year: "2020",
    instrumentation: "Brass Choir",
    category: "Brass Choir",
  },
];
export const ChamberCompositions: ICompositionCatalogItem[] = [
  {
    title: "Where There's Light",
    description: "Solo clarinet with piano",
    year: "2023",
    instrumentation: "B flat clarinet, piano",
    category: "Chamber - Winds - Clarinet-Piano",
    gradeLevel: "Medium-Advanced",
    imageUrl: ChamberGenericGrade35,
  },
  {
    title: "The First Solo Flight",
    description:
      "For horn and piano. A heroic tonal piece, included in the Lift: Solos for Horn by Black Composers anthology.",
    publisherLink: "https://www.legacyhornexperience.com/store/p/lift",
    year: "2022",
    instrumentation: "F Horn, Piano",
    category: "Chamber - Winds - Horn-Piano",
    gradeLevel: "Medium Easy",
    imageUrl: ChamberGenericGrade35,
  },
  {
    title: "Divertimento for Clarinet Ensemble",
    description:
      "Themes from one of my young band compositions are further developed and woven into a multi-movement light concert work for clarinet ensemble (6 performers).",
    imageUrl: ChamberGenericGrade35,
    audioRecordingLink: "https://youtu.be/RZqrl5_V6b8",
    gradeLevel: "3.5",
    year: "2020",
    instrumentation: "Clarinet Ensemble (Ebcl. 3cl, acl, bcl)",
    category: "Chamber - Winds - Clarinet",
  },
  {
    imageUrl: SoloChamberClarinetGenericScoreCover,
    title: "Affirmation and Ballade",
    description: "Solo work for more advanced performer.",
    gradeLevel: "Advanced",
    year: "2002/2021",
    instrumentation: "Solo B flat clarinet",
    category: "Chamber - Winds - Clarinet",
  },
];
