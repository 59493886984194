import { IProject } from "./types";
import SyncedUpScreenCapture1 from "../../../../assets/images/app-screen-captures/synced-up-1.jpg";
import SyncedUpScreenCapture2 from "../../../../assets/images/app-screen-captures/synced-up-2.jpg";
export const projects: IProject[] = [
  {
    name: "Synced Up!",
    description:
      "This is a Chingu Voyage 25 team project - a full-stack linked in clone using React-Typescript, approached with TDD in mind. Project has front-end and back-end repo.",
    githubLink: "https://github.com/chingu-voyages/v25-bears-team-05",
    alternateGithubLink: {
      label: "back-end-repo",
      url: "https://github.com/chingu-voyages/v25-bears-team-05-repo2",
    },
    deployUrl: "https://syncedup.live/",
    stackTags: [
      "React",
      "redux",
      "typescript",
      "node",
      "mongodb",
      "jest",
      "express",
      "passport",
      "googleAuth",
    ],
    screenCapUrls: [SyncedUpScreenCapture1, SyncedUpScreenCapture2],
  },
  {
    name: "Easy Weather",
    description:
      "Desktop app using the openWeather API and ElectronJs for Windows / Mac / Linux. It shows up-to-date weather data for the location of your choosing.",
    githubLink: "https://github.com/davideastmond/easy-weather",
    screenCapUrls: [
      "https://raw.githubusercontent.com/davideastmond/easy-weather/master/docs/img/m26_main.jpg",
      "https://raw.githubusercontent.com/davideastmond/easy-weather/master/docs/img/m26_radar.jpg",
    ],
    stackTags: ["electron", "jest", "leaflet", "openWeather API"],
  },
  {
    name: "News One",
    description: "A full-stack news aggregator app using newsAPI and mailgun",
    githubLink: "https://github.com/davideastmond/newsaggregator",
    screenCapUrls: [
      "https://raw.githubusercontent.com/davideastmond/newsaggregator/master/docs/1_login.png",
      "https://raw.githubusercontent.com/davideastmond/newsaggregator/master/docs/1_bookmarks.png",
    ],
    stackTags: ["jQuery", "node", "ejs", "postgreSql", "mongodb"],
  },
  {
    name: "Chingu Trivia",
    description: "",
    githubLink: "https://github.com/davideastmond/chingu-trivia",
    screenCapUrls: [
      "https://raw.githubusercontent.com/davideastmond/chingu-trivia/main/resources/full-screen-shot.jpg",
    ],
    stackTags: ["React", "Typescript", "jest", "axios", "bootstrap"],
  },
  {
    name: "Word Solver",
    description:
      "Helps users play Scrabble, hang man, Words With Friends or other word guessing games",
    githubLink: "https://github.com/davideastmond/wordsolver_web",
    screenCapUrls: [
      "https://raw.githubusercontent.com/davideastmond/wordsolver_web/master/res/wordSolverJul222019.png",
    ],
    stackTags: ["React", "react-alert", "bootstrap"],
  },
];
