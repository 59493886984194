import React from "react";
import { Link } from "react-router-dom";

function MenuItems({
  classNameInfo,
  activeLink,
}: {
  classNameInfo?: string;
  activeLink?: string;
}) {
  return (
    <nav className={`${classNameInfo || ""}`}>
      <Link
        className={`Top-bar__nav__link ${
          activeLink === "home" ? "link-active" : "link-inactive"
        }`}
        to="/"
      >
        Home
      </Link>
      <Link
        className={`Top-bar__nav__link ${
          activeLink === "news" ? "link-active" : "link-inactive"
        }`}
        to="/news"
      >
        News
      </Link>
      <Link
        className={`Top-bar__nav__link ${
          activeLink === "music" ? "link-active" : "link-inactive"
        }`}
        to="/music"
      >
        Music
      </Link>
      <Link
        className={`Top-bar__nav__link ${
          activeLink === "dev" ? "link-active" : "link-inactive"
        }`}
        to="/dev"
      >
        Dev
      </Link>
      <Link
        className={`Top-bar__nav__link ${
          activeLink === "bio" ? "link-active" : "link-inactive"
        }`}
        to="/bio"
      >
        Bio
      </Link>
      <Link
        className={`Top-bar__nav__link ${
          activeLink === "media" ? "link-active" : "link-inactive"
        }`}
        to="/media"
      >
        Media
      </Link>
      <Link
        className={`Top-bar__nav__link ${
          activeLink === "contact" ? "link-active" : "link-inactive"
        }`}
        to="/contact"
      >
        Contact
      </Link>
    </nav>
  );
}

export default MenuItems;
